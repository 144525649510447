import React from 'react'
import PropTypes from 'prop-types'

import { ChartCol, ChartRow } from 'neptune-signature-web/src/components/charts'
import { PageContent } from 'neptune-web-core/src/components/layouts'
import {
  CommunityOverview,
  MemberBreakdown,
  MemberGrowth,
  SocialMedia,
} from 'neptune-signature-web/src/components/analytics'

import { dataSchema } from '../../dataSchema'

const AdministratorView = ({ data, loading }) => (
  <PageContent>
    <ChartRow>
      <ChartCol>
        <CommunityOverview communityScore={data.community_score} loading={loading} />
      </ChartCol>
      <SocialMedia />
    </ChartRow>
    <ChartRow>
      <ChartCol>
        <MemberBreakdown />
      </ChartCol>
      <MemberGrowth />
    </ChartRow>
  </PageContent>
)

AdministratorView.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape),
  loading: PropTypes.bool,
}

AdministratorView.defaultProps = {
  data: dataSchema,
  loading: true,
}
export default AdministratorView
