import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as ChartIcon } from 'neptune-react-core/src/assets/images/icons/chart-bar.svg'

import { PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'
import { SignatureResource } from 'neptune-web-core/src/api/resources'
import { useCommunity } from 'neptune-web-core/src/context/community'
import { useUtilityMenu } from 'neptune-web-core/src/context'
import { withDisabledUtilityMenu } from 'neptune-web-core/src/hocs'

import AdministratorView from './views/Administrator'
import AmbassadorView from './views/Ambassador'
import CommunityManagerView from './views/CommunityManager'
import SignatureUtilMenu from '../../../components/utilityMenu/SignatureUtilMenu'
import { dataSchema } from './dataSchema'

import './index.scss'

const userTypeViews = {
  administrator: AdministratorView,
  ambassador: AmbassadorView,
  community_manager: CommunityManagerView,
}

const UserView = ({ currentCommunity, data, loading, isFetchSuccessful, userType }) => {
  const UserViewComponent = userTypeViews[userType]

  return (
    <UserViewComponent
      currentCommunity={currentCommunity}
      data={data}
      loading={loading}
      isFetchSuccessful={isFetchSuccessful}
    />
  )
}

const SignaturePage = () => {
  const { setUtilMenuContent, setUtilMenuTitle, setUtilMenuIcon } = useUtilityMenu()
  const { currentCommunity } = useCommunity()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(dataSchema)
  const [isFetchSuccessful, setIsFetchSuccessful] = useState(false)

  const userType = 'administrator'

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await SignatureResource.getAll(currentCommunity)
      setData(response.data)
      setIsFetchSuccessful(true)
    } catch (error) {
      setData(dataSchema)
      setIsFetchSuccessful(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setUtilMenuContent(<SignatureUtilMenu />)
    setUtilMenuIcon(<ChartIcon />)
    setUtilMenuTitle('Signature')

    return () => {
      setUtilMenuContent(null)
      setUtilMenuIcon(null)
      setUtilMenuTitle(null)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [currentCommunity])

  useEffect(() => {
    // There should only ever be one element with a PageContent class name
    const pageContentEle = document.getElementsByClassName('PageContent')[0]
    pageContentEle.scrollTo({
      // behavior: 'smooth',
      top: 0,
    })
  }, [currentCommunity])

  return (
    <PageWrapper name="SignaturePage">
      <PageTitle>Signature</PageTitle>
      <UserView
        currentCommunity={currentCommunity}
        data={data}
        isFetchSuccessful={isFetchSuccessful}
        loading={loading}
        userType={userType}
      />
    </PageWrapper>
  )
}

UserView.propTypes = {
  currentCommunity: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.shape),
  loading: PropTypes.bool,
  isFetchSuccessful: PropTypes.bool,
  userType: PropTypes.string,
}

UserView.defaultProps = {
  currentCommunity: 'TX',
  data: {},
  loading: true,
  isFetchSuccessful: false,
  userType: '',
}

export default withDisabledUtilityMenu(SignaturePage)
