import React, { useEffect, useState } from 'react'

import { ReactComponent as PlayIcon } from 'neptune-react-core/src/assets/images/icons/play.svg'

import LoadingContainer from 'neptune-web-core/src/components/common/LoadingContainer'
import { PageContent, PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'
import { PaginatedListItems } from 'neptune-web-core/src/components/common'
import { VideosResource } from 'neptune-web-core/src/api/resources'
import { useUtilityMenu } from 'neptune-web-core/src/context'
import { withActiveUtilityMenu } from 'neptune-web-core/src/hocs'

import './index.scss'

const VideosIndexPage = () => {
  const { setUtilMenuContent, setUtilMenuTitle, setUtilMenuIcon } = useUtilityMenu()

  const [loading, setLoading] = useState(true)
  const [videos, setVideos] = useState([])

  const getVideos = async () => {
    const response = await VideosResource.getIndex({
      token: process.env.REACT_APP_FILE_STORAGE_TOKEN,
    })
    setLoading(false)
    setVideos(response.files)
  }

  useEffect(() => {
    setUtilMenuContent(null)
    setUtilMenuIcon(<PlayIcon />)
    setUtilMenuTitle('Content')
    getVideos()

    return () => {
      setUtilMenuContent(null)
      setUtilMenuIcon(null)
      setUtilMenuTitle(null)
    }
  }, [])

  return (
    <PageWrapper name="VideosIndexPage">
      <PageTitle>Content</PageTitle>
      <PageContent>
        <LoadingContainer
          loading={loading}
          hasContent={!!videos.length}
          resource="documents"
        >

          <PaginatedListItems
            hrefProp="file_name"
            isLink
            itemType="file"
            items={videos}
            keyProp="file_name"
          />
        </LoadingContainer>
      </PageContent>
    </PageWrapper>
  )
}

export default withActiveUtilityMenu(VideosIndexPage)
