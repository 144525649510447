import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { PageContent, PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'
import { ReactComponent as QuestionIcon } from 'neptune-web-core/src/assets/images/icons/question.svg'
import { useUtilityMenu } from 'neptune-web-core/src/context'
import { withActiveUtilityMenu } from 'neptune-web-core/src/hocs'

import SupportUtilMenu from '../../../components/utilityMenu/SupportUtilMenu'

import './index.scss'

const SupportPage = () => {
  const { setUtilMenuContent, setUtilMenuTitle, setUtilMenuIcon } = useUtilityMenu()
  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.pathname.replaceAll('/', '') === 'support') navigate('data')
  }, [])

  useEffect(() => {
    setUtilMenuContent(<SupportUtilMenu />)
    setUtilMenuIcon(<QuestionIcon />)
    setUtilMenuTitle('Support')

    return () => {
      setUtilMenuContent(null)
      setUtilMenuIcon(null)
      setUtilMenuTitle(null)
    }
  }, [])

  return (
    <PageWrapper name="SupportPage">
      <PageTitle>Support</PageTitle>
      <PageContent>
        <Outlet />
      </PageContent>
    </PageWrapper>
  )
}

export default withActiveUtilityMenu(SupportPage)
