import React from 'react'

import { UtilityMenuLink, UtilityMenuList } from 'neptune-web-core/src/components/layouts'

const SignatureUtilMenu = () => (
  <UtilityMenuList>
    <UtilityMenuLink to="/signature/administrator">
      Administrator
    </UtilityMenuLink>
    <UtilityMenuLink to="/signature/ambassador">
      Ambassador
    </UtilityMenuLink>
    <UtilityMenuLink to="/signature/community_manager">
      Community Manager
    </UtilityMenuLink>
  </UtilityMenuList>
)

SignatureUtilMenu.propTypes = {
}

SignatureUtilMenu.defaultProps = {
}

export default SignatureUtilMenu
