export const dataSchema = {
  article_engagement: [{}, {}, {}],
  community_score: { value: 0 },
  member_breakdown: [{}, {}, {}, {}],
  member_growth: {
    growth: [{}, {}, {}],
    total: [{}, {}, {}],
  },
  member_location: [{}, {}, {}],
  member_support: [{}, {}, {}],
  members: [],
  national_concerns: { type: 'national', concerns: [] },
  sentiment_analysis: {
    mtd: { value: null },
    total: { value: null },
  },
  state_concerns: { type: 'national', concerns: [] },
  surveys: [],
}
