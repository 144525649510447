import React from 'react'

import About from '../../pages/static/About'
import Contact from '../../pages/static/Contact'
import SupportData from '../../pages/support/Data'
import SupportIndex from '../../pages/support/Index'

const publicRoutes = [
  {
    children: [
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/contact',
        element: <Contact />,
      },
      {
        path: '/support',
        element: <SupportIndex />,
        children: [
          {
            path: 'data',
            element: <SupportData />,
          }
        ]
      },
    ]
  },
]

export default publicRoutes
