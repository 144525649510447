import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { MsalProvider } from '@azure/msal-react'
import { EventType, PublicClientApplication } from '@azure/msal-browser'

// import { Layout, Menu, UtilityMenu } from 'neptune-web-core/src/components/layouts'
import DataDogService from 'neptune-web-core/src/services/DataDogService'
import store from 'neptune-web-core/src/store'
import { CommunityProvider } from 'neptune-web-core/src/context/community'
import { ThemeProvider, UtilityMenuProvider } from 'neptune-web-core/src/context'
import { msalConfig } from 'neptune-web-core/src/config/msal'

import { ExternalitiesProvider } from 'neptune-externalities-web/src/context/utilityMenu/Externalities'

import Layout from './components/Layout'
import Router from './routes/router'
import reportWebVitals from './reportWebVitals'

import 'neptune-react-core/src/assets/stylesheets/global.scss'

const container = document.getElementById('root')
const root = createRoot(container)

const msalInstance = new PublicClientApplication(msalConfig)

DataDogService.start()

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents()

msalInstance.addEventCallback((event) => {
  // console.info('%cMSAL EVENT TYPE: %s', 'color: orange', '%c%s', 'color: lightgreen', event.eventType);

  if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
    const { account } = event.payload
    msalInstance.setActiveAccount(account)
  }
})

// TODO: write a function that allows wrapping a provider around UtilityMenuProvider from within page component.
//   Example: export ExternalitiesPage that calls this function and wraps the ExternalitiesProvider around the
//   UtilityMenuProvider so both the utility menu and the page can use the same context, and we don't have to keep
//   wrapping page-specific providers around the UtilityMenuProvider at the application level

root.render(
  <StrictMode>
    <CookiesProvider>
      <ReduxProvider store={store}>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <CommunityProvider>
              <ThemeProvider theme="mwi">
                <ExternalitiesProvider>
                  <UtilityMenuProvider>
                    <Layout>
                      <Router />
                    </Layout>
                  </UtilityMenuProvider>
                </ExternalitiesProvider>
              </ThemeProvider>
            </CommunityProvider>
          </BrowserRouter>
        </MsalProvider>
      </ReduxProvider>
    </CookiesProvider>
  </StrictMode>
)

reportWebVitals()
