import React from 'react'

import { PageContent, PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'
import { withInactiveUtilityMenu } from 'neptune-web-core/src/hocs'

import './index.scss'

const NotFoundPage = () => (
  <PageWrapper name="NotFoundPage" title="Not Found">
    <PageTitle>404</PageTitle>
    <PageContent>
      <h1>Page not found</h1>
    </PageContent>
  </PageWrapper>
)

export default withInactiveUtilityMenu(NotFoundPage)
