import React from 'react'

import { ReactComponent as DocumentIcon } from 'neptune-react-core/src/assets/images/icons/document.svg'

import { UtilityMenuLink, UtilityMenuList } from 'neptune-web-core/src/components/layouts'

const SupportUtilMenu = () => (
  <UtilityMenuList>
    <UtilityMenuLink to="/support/data" icon={<DocumentIcon />}>
      Data Collection
    </UtilityMenuLink>
  </UtilityMenuList>
)

export default SupportUtilMenu
