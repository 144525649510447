import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ReactComponent as CaretRight } from 'neptune-react-core/src/assets/images/icons/caret-right.svg'

import './index.scss'

const ArticleTitle = ({ children, onClick }) => (
  <button className="ArticleTitle" onClick={onClick}>
    <b>{children}</b>
    <CaretRight />
  </button>
)

const SupportArticle = ({ children, id, title }) => {
  const [active, setActive] = useState(true)

  return (
    <div
      className={classNames('SupportArticle', { active })}
      id={id}
    >
      <ArticleTitle onClick={() => setActive(!active)}>{title}</ArticleTitle>
      <div className="SupportArticle__content">
        {children}
      </div>
    </div>
  )
}

const SupportData = () => {
  const supportDataRef = useRef(null)

  useEffect(() => {
    const { hash } = window.location
    if (hash) {
      try {
        document.getElementById(hash.replace('#', '')).scrollIntoView()
      } finally {
        // Do nothing
      }
    }
  }, [])

  return (
    <div id="SupportData" ref={supportDataRef}>
      <h2>FAQ</h2>
      <h1>Data Collection</h1>

      <div className="SupportArticles">
        <SupportArticle title="Sentiment Analysis" id="sentiment-analysis">
          <p>
            Sentiment analysis MTD is a monthly metric showing multiple behaviors from our members that we measure and score out of 100. This includes unsubscribes, positive versus negative comments, and social platform metrics.
          </p>
          <p>
            Sentiment analysis is a metric that represents the sentiment over the last 12 months. This includes unsubscribes, positive versus negative comments, and social platform metrics.
          </p>
        </SupportArticle>
        <SupportArticle title="Surveys" id="surveys">
          <p>
            Surveys Completed are the number of surveys collected from the inception of MWi, and MTD. MWi uses surveys annually to refresh the data across all 54+1 communities. This member participation is how we give a voice to each community.
          </p>
        </SupportArticle>
        <SupportArticle title="Community Score" id="community-score">
          <p>
            A community score is a weighted score that places all communities on a level playing field. MWi takes the total addressable market in each community and uses that to create a score with recognized members.
          </p>
        </SupportArticle>
      </div>
    </div>
  )
}

ArticleTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}

SupportArticle.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
}

SupportArticle.defaultProps = {
  id: null,
}

export default SupportData
