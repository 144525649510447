import React from 'react'

import { withInactiveUtilityMenu } from 'neptune-web-core/src/hocs'
import { PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'
import { SettingsForm } from 'neptune-web-core/src/components/forms'
import { ButtonBlack } from 'neptune-web-core/src/components/buttons/Button'
// import { useAuth } from 'neptune-web-core/src/context'

import './index.scss'

const SettingsPage = () => {
  // const { signOut } = useAuth()
  const onSignOutClick = () => {
    // signOut()
  }

  return (
    <PageWrapper name="SettingsPage">
      <PageTitle>Settings</PageTitle>
      <div className="SettingsCards">
        {/* <SettingsForm /> */}
        <div className="Logout__container">
          <ButtonBlack onClick={onSignOutClick}>Log Out</ButtonBlack>
        </div>
      </div>
    </PageWrapper>
  )
}

export default withInactiveUtilityMenu(SettingsPage)
