import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { DocumentsResource } from 'neptune-web-core/src/api/resources'
import { PageContent, PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'
import { iconTypes } from 'neptune-web-core/src/components/common'
import { useUtilityMenu } from 'neptune-web-core/src/context/utilityMenu'
import { withActiveUtilityMenu } from 'neptune-web-core/src/hocs'

import FileCommentsUtilMenu from '../../../components/utilityMenu/FileCommentsUtilMenu'

import './index.scss'

const DocumentsShowPage = () => {
  const { id } = useParams()
  const [document, setDocument] = useState(null)

  const { setUtilMenuContent, setUtilMenuTitle, setUtilMenuIcon } = useUtilityMenu()

  const getDocument = async () => {
    const response = await DocumentsResource.getShow({
      token: process.env.REACT_APP_FILE_STORAGE_TOKEN,
      fileName: id,
    })
    return response
  }

  const initializePageData = async () => {
    const documentResponse = await getDocument()
    setDocument(documentResponse)
  }

  useEffect(() => {
    initializePageData()

    return () => {
      setUtilMenuContent(null)
      setUtilMenuIcon(null)
      setUtilMenuTitle(null)
    }
  }, [])

  useEffect(() => {
    if (document) {
      const { title, file_name, content_type } = document
      setUtilMenuContent(<FileCommentsUtilMenu file={document} />)
      setUtilMenuTitle(title || file_name)
      setUtilMenuIcon(iconTypes[content_type])
      setUtilMenuIcon(iconTypes['application/pdf'])
    }
  }, [document])

  if (!document) return null

  const { title, file_name, file_location } = document

  return (
    <PageWrapper name="DocumentsShowPage">
      <PageTitle>{title || file_name}</PageTitle>
      <PageContent>
        <object
          className="Document__object"
          data={file_location}
          aria-label={file_name}
          type="application/pdf"
          width="100%"
        />
      </PageContent>
    </PageWrapper>
  )
}

export default withActiveUtilityMenu(DocumentsShowPage)
