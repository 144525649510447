import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ToastContainer, Flip } from 'react-toastify'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Menu, UtilityMenu } from 'neptune-web-core/src/components/layouts'

import 'react-toastify/dist/ReactToastify.css'

import './index.scss'

const LayoutContainer = ({ children, menuActive, noMenu }) => (
  <div
    className={cx('Layout__container', {
      'Layout__container--menu-active': menuActive,
      'no-menu': noMenu
    })}
  >
    <main id="Layout" className={cx({ 'no-menu': noMenu })}>
      {children}
    </main>
  </div>
)

const Layout = ({ children }) => {
  const menuActive = useSelector((state) => state.menu.active)
  const { pathname } = useLocation()
  const noMenu = pathname.includes('/users')

  return (
    <div className={cx('App', { 'no-menu': noMenu })}>
      <Menu pathname={pathname} />
      <UtilityMenu />
      <LayoutContainer menuActive={menuActive} noMenu={noMenu}>
        {children}
      </LayoutContainer>
      <ToastContainer
        autoClose={2000}
        hideProgressBar
        limit={1}
        position="top-center"
        style={{ minWidth: '15em', maxWidth: '50em', width: 'fit-content' }}
        theme="dark"
        transition={Flip}
      />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

LayoutContainer.propTypes = {
  children: PropTypes.node.isRequired,
  menuActive: PropTypes.bool.isRequired,
  noMenu: PropTypes.bool.isRequired,
}

export default Layout
