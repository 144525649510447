import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ReactComponent as FolderIcon } from 'neptune-react-core/src/assets/images/icons/folder.svg'

import LoadingContainer from 'neptune-web-core/src/components/common/LoadingContainer'
import PaginatedListItems from 'neptune-web-core/src/components/common/PaginatedListItems'
import { DocumentsResource } from 'neptune-web-core/src/api/resources'
import { PageContent, PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'
import { useUtilityMenu } from 'neptune-web-core/src/context'
import { withActiveUtilityMenu } from 'neptune-web-core/src/hocs'

import DocumentsUtilMenu from '../../../components/utilityMenu/DocumentsUtilMenu'

import './index.scss'

const docTypeTitles = {
  contract: 'Administrative Documents and Contracts',
  internal: 'MWi Documents',
  invoice: 'Invoices',
  product: 'Products and Deliverables',
}

const DocumentsIndex = () => {
  const { docType } = useParams()
  const { setUtilMenuContent, setUtilMenuTitle, setUtilMenuIcon } = useUtilityMenu()

  const [documents, setDocuments] = useState([])
  const [loading, setLoading] = useState(true)

  // TODO: throw abort signal for previous fetch when docType effect is triggered
  const getDocuments = async () => {
    const response = await DocumentsResource.getIndexByType({
      token: process.env.REACT_APP_FILE_STORAGE_TOKEN,
      type: docType,
    })
    setLoading(false)
    setDocuments(response.files)
  }

  useEffect(() => {
    setUtilMenuContent(<DocumentsUtilMenu />)
    setUtilMenuIcon(<FolderIcon />)
    setUtilMenuTitle('Documents')
    getDocuments()

    return () => {
      setUtilMenuContent(null)
      setUtilMenuIcon(null)
      setUtilMenuTitle(null)
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    setDocuments([])
    getDocuments()
  }, [docType])

  return (
    <PageWrapper name="DocumentsIndexPage">
      <PageTitle>{docTypeTitles[docType]}</PageTitle>
      <PageContent>
        <LoadingContainer
          loading={loading}
          hasContent={!!documents.length}
          resource="documents"
        >
          <PaginatedListItems
            hrefProp="file_name"
            items={documents}
            itemType="file"
            isLink
            keyProp="file_name"
          />
        </LoadingContainer>
      </PageContent>
    </PageWrapper>
  )
}

export default withActiveUtilityMenu(DocumentsIndex)
