/* eslint-disable */
import React, { useEffect } from 'react'

import { PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'
import { useUtilityMenu } from 'neptune-web-core/src/context'
import { withActiveUtilityMenu, withProvider } from 'neptune-web-core/src/hocs'

import Externalities from 'neptune-externalities-web/src/components/Externalities'
import ExternalitiesUtilMenu from 'neptune-externalities-web/src/components/utilityMenu/ExternalitiesUtilMenu'
// import { ExternalitiesProvider } from 'neptune-externalities-web/src/context/utilityMenu/Externalities'
// import { useProviderWithUtilityMenu } from 'neptune-web-core/src/context/utilityMenu'

import { ReactComponent as GlobeIcon } from 'neptune-react-core/src/assets/images/icons/globe-regular.svg'

// import { getEvents } from './data'

import './index.scss'

const ExternalitiesPage = () => {
  // const [events, setEvents] = useState(getEvents())
  const { setUtilMenuContent, setUtilMenuTitle, setUtilMenuIcon } = useUtilityMenu()

  useEffect(() => {
    setUtilMenuContent(<ExternalitiesUtilMenu />)
    setUtilMenuIcon(<GlobeIcon />)
    setUtilMenuTitle('Externalities')

    return () => {
      setUtilMenuContent(null)
      setUtilMenuIcon(null)
      setUtilMenuTitle(null)
    }
  }, [])

  return (
    <PageWrapper name="ExternalitiesPage">
      <PageTitle>Externalities</PageTitle>
      <Externalities />
    </PageWrapper>
  )
}

// export default withActiveUtilityMenu(withProvider(ExternalitiesProvider)(ExternalitiesPage))
// export default withProvider(ExternalitiesProvider)(withActiveUtilityMenu(ExternalitiesPage))
// export default useProviderWithUtilityMenu(ExternalitiesProvider)(withActiveUtilityMenu(ExternalitiesPage))
export default withActiveUtilityMenu(ExternalitiesPage)
