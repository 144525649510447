import React from 'react'

import { PageContent, PageWrapper } from 'neptune-web-core/src/components/layouts'

import './index.scss'

const AboutPage = () => (
  <PageWrapper name="About">
    <PageContent>
      <h1>About</h1>
    </PageContent>
  </PageWrapper>
)

export default AboutPage
