import React from 'react'

import { PageContent, PageWrapper } from 'neptune-web-core/src/components/layouts'

import './index.scss'

const ContactPage = () => (
  <PageWrapper name="ContactPage">
    <PageContent>
      <h1>Contact</h1>
    </PageContent>
  </PageWrapper>
)

export default ContactPage
