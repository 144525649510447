import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import autosize from 'autosize'
import axios from 'axios'

import { ButtonBlack } from 'neptune-web-core/src/components/buttons'
// import { useAuth } from 'neptune-web-core/src/context'

import './index.scss'

const Comment = ({ comment }) => {
  const { avatar, first_name, last_name, content } = comment

  return (
    <div className="Comment">
      <div className="Comment__header">
        <div className="Comment__avatar">
          <img src={avatar} alt="" />
        </div>
        <div className="Comment__identity">
          <div className="Comment__name">
            {first_name} {last_name}
          </div>
          <div className="Comment__time">
            4 hours ago
          </div>
        </div>
      </div>
      <div className="Comment__content">{content}</div>
    </div>
  )
}

const UserCommentForm = ({ onSubmit, user }) => {
  const { avatar, email, first_name, last_name } = user

  const [content, setContent] = useState('')

  const onContentChange = (e) => {
    setContent(e.target.value)
  }

  const submitComment = (e) => {
    e.preventDefault()
    if (!content.length) return

    // TODO: put into real form submission with response
    const id = Math.floor(Math.random() * 10000)
    const comment = { avatar, email, first_name, id, last_name, content }
    onSubmit(comment)
  }

  return (
    <form className="UserComment">
      <div className="UserComment__title">
        <img src={avatar} alt="user" className="Comment__avatar" />
        <div className="UserComment__name">{first_name} {last_name}</div>
      </div>
      <div className="UserComment__comment">
        <textarea
          id="UserComment__content"
          onChange={onContentChange}
          rows="1"
          value={content}
        />
        <ButtonBlack
          className="Comment__submit"
          onClick={submitComment}
          opts={{ scale: 0.65 }}
        >
          Comment
        </ButtonBlack>
      </div>
    </form>
  )
}

const FileCommentsUtilMenu = () => {
  const [comments, setComments] = useState([])

  // const { currentUser } = useAuth()

  const initializePageData = async () => {
    autosize(document.querySelector('textarea#UserComment__content'))
    const response = await axios.get('https://my.api.mockaroo.com/neptune_file_comments.json?key=a97ec2d0')
    setComments(response.data)
  }

  // const onCommentSubmit = (comment) => {
  //   setComments([comment, ...comments])
  // }

  useEffect(() => {
    initializePageData()
  }, [])

  return (
    <div className="FileCommentsUtilMenu">
      <div className="FileComments">
        {comments.map((comment) => (
          <Comment comment={comment} key={`Comment__${comment.id}`} />
        ))}
      </div>
      {/* <UserCommentForm
        onSubmit={(comment) => onCommentSubmit(comment)}
        user={currentUser}
      /> */}
    </div>
  )
}

Comment.propTypes = {
  comment: PropTypes.shape({
    avatar: PropTypes.string,
    content: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }).isRequired
}

FileCommentsUtilMenu.propTypes = {
  // file: PropTypes.string,
}

UserCommentForm.propTypes = {
  onSubmit: PropTypes.isRequired,
  user: PropTypes.objectOf(PropTypes.shape),
}

FileCommentsUtilMenu.defaultProps = {
  file: ''
}

UserCommentForm.defaultProps = {
  user: null,
}

export default FileCommentsUtilMenu
