import React from 'react'
import PropTypes from 'prop-types'

import { ChartCol, ChartRow, ListCard } from 'neptune-signature-web/src/components/charts'
import { PageContent } from 'neptune-web-core/src/components/layouts'
import {
  ArticleEngagement,
  CommunityOverview,
  MemberBreakdown,
  MemberGrowth,
  MemberLocation,
  MemberSupport,
  SentimentAnalysis,
  Surveys,
} from 'neptune-signature-web/src/components/analytics'

import { dataSchema } from '../../dataSchema'

const AdministratorView = ({ currentCommunity, data, isFetchSuccessful, loading }) => {
  const {
    article_engagement,
    community_score,
    members,
    member_breakdown,
    member_growth,
    member_location,
    member_support,
    national_concerns,
    sentiment_analysis,
    state_concerns,
    surveys,
  } = data

  return (
    <PageContent>
      <ChartRow>
        <CommunityOverview
          communityScore={community_score}
          currentCommunity={currentCommunity}
          loading={loading}
          members={members}
          isFetchSuccessful={isFetchSuccessful}
        />
        <MemberLocation
          data={member_location}
          loading={loading}
          isFetchSuccessful={isFetchSuccessful}
        />
        <MemberSupport
          data={member_support}
          loading={loading}
          isFetchSuccessful={isFetchSuccessful}
        />
      </ChartRow>
      <ChartRow>
        <MemberBreakdown
          data={member_breakdown}
          loading={loading}
          isFetchSuccessful={isFetchSuccessful}
        />
        <MemberGrowth
          data={member_growth}
          loading={loading}
          isFetchSuccessful={isFetchSuccessful}
        />
        <ChartCol className="Sentiment__col">
          <SentimentAnalysis
            data={sentiment_analysis.mtd}
            loading={loading}
            type="mtd"
            isFetchSuccessful={isFetchSuccessful}
          />
          <SentimentAnalysis
            data={sentiment_analysis.total}
            loading={loading}
            type="total"
            isFetchSuccessful={isFetchSuccessful}
          />
        </ChartCol>
      </ChartRow>
      <ChartRow>
        <ArticleEngagement
          data={article_engagement}
          loading={loading}
          isFetchSuccessful={isFetchSuccessful}
        />
        <Surveys
          data={surveys}
          loading={loading}
          isFetchSuccessful={isFetchSuccessful}
        />
        <ChartCol className="Concerns__col">
          <ListCard
            title="State Concerns"
            items={state_concerns.concerns}
            loading={loading}
          />
          <ListCard
            title="National Concerns"
            items={national_concerns.concerns}
            loading={loading}
          />
        </ChartCol>

      </ChartRow>
    </PageContent>
  )
}

AdministratorView.propTypes = {
  currentCommunity: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.shape),
  isFetchSuccessful: PropTypes.bool,
  loading: PropTypes.bool,
}

AdministratorView.defaultProps = {
  data: dataSchema,
  isFetchSuccessful: false,
  loading: true,
}
export default AdministratorView
