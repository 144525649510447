import React from 'react'
import { useMsal } from '@azure/msal-react'

import { withInactiveUtilityMenu } from 'neptune-web-core/src/hocs'
import { PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'

import './index.scss'

const ProfilePage = () => (
  <PageWrapper name="ProfilePage">
    <PageTitle>Profile</PageTitle>
    <div className="ProfileCards">
      {/* <h1>Logged in as:</h1>
      <p>{account.name}</p>
      <p>{account.username}</p> */}
      {/* <ProfileForm /> */}
    </div>
  </PageWrapper>
)

export default withInactiveUtilityMenu(ProfilePage)
