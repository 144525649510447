import React from 'react'

import { ReactComponent as ChartIcon } from 'neptune-react-core/src/assets/images/icons/chart.svg'
import { ReactComponent as ContractIcon } from 'neptune-react-core/src/assets/images/icons/contract.svg'
import { ReactComponent as DocumentIcon } from 'neptune-react-core/src/assets/images/icons/document.svg'
import { ReactComponent as InvoiceIcon } from 'neptune-react-core/src/assets/images/icons/invoice.svg'

import { UtilityMenuLink, UtilityMenuList } from 'neptune-web-core/src/components/layouts'

const DocumentsUtilMenu = () => (
  <UtilityMenuList>
    <UtilityMenuLink to="/documents/internal" icon={<DocumentIcon />}>
      MWi Documents
    </UtilityMenuLink>
    {/* <UtilityMenuLink to="/documents/product" icon={<ChartIcon />}>
      Products
    </UtilityMenuLink>
    <UtilityMenuLink to="/documents/invoice" icon={<InvoiceIcon />}>
      Invoices
    </UtilityMenuLink>
    <UtilityMenuLink to="/documents/contract" icon={<ContractIcon />}>
      Contracts
    </UtilityMenuLink> */}
  </UtilityMenuList>
)

DocumentsUtilMenu.propTypes = {
}

DocumentsUtilMenu.defaultProps = {
}

export default DocumentsUtilMenu
