import React from 'react'
import PropTypes from 'prop-types'

import { ChartCol, ChartRow, ListCard } from 'neptune-signature-web/src/components/charts'
import { PageContent } from 'neptune-web-core/src/components/layouts'
import {
  CommunityOverview,
  MemberBreakdown,
  SocialMedia,
} from 'neptune-signature-web/src/components/analytics'

import { dataSchema } from '../../dataSchema'

const AdministratorView = ({ data, loading }) => (
  <PageContent>
    <ChartRow>
      <ChartCol>
        <CommunityOverview communityScore={data.community_score} />
      </ChartCol>
      <SocialMedia />
    </ChartRow>
    <ChartRow>
      <ChartCol>
        <ListCard
          title="State Concerns"
          items={data.state_concerns.concerns}
          loading={loading}
        />
        <ListCard
          title="National Concerns"
          items={data.national_concerns.concerns}
          loading={loading}
        />
      </ChartCol>
      <ChartCol>
        <MemberBreakdown />
      </ChartCol>
    </ChartRow>
  </PageContent>
)

AdministratorView.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape),
  loading: PropTypes.bool,
}

AdministratorView.defaultProps = {
  data: dataSchema,
  loading: true,
}
export default AdministratorView
