import React from 'react'
import { Navigate } from 'react-router-dom'

import Signature from '../../pages/static/Signature'
import DocumentsIndex from '../../pages/documents/IndexPage'
import DocumentsShow from '../../pages/documents/ShowPage'
import Externalities from '../../pages/static/Externalities'
import Profile from '../../pages/users/Profile'
import Settings from '../../pages/users/Settings'
import VideosIndex from '../../pages/videos/IndexPage'
import VideosShow from '../../pages/videos/ShowPage'

const privateRoutes = [
  {
    path: '/',
    element: <Navigate to="/signature" />,
  },
  {
    path: '/documents/:docType',
    element: <DocumentsIndex />,
  },
  {
    path: '/documents/:docType/:id',
    element: <DocumentsShow />,
  },
  {
    path: '/signature',
    element: <Signature />,
  },
  {
    path: '/externalities',
    element: <Externalities />,
  },
  {
    path: '/videos',
    element: <VideosIndex />,
  },
  {
    path: '/videos/:id',
    element: <VideosShow />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/settings',
    element: <Settings />,
  },
]

export default privateRoutes
