import React from 'react'
import { useMsal } from '@azure/msal-react'
import { useRoutes } from 'react-router-dom'

import { PrivateRoute } from 'neptune-web-core/src/components/common'

import NotFoundPage from '../pages/static/NotFound'
import privateRoutes from './config/private'
import publicRoutes from './config/public'

const Router = () => {
  const { instance } = useMsal()

  const routesConfig = [
    ...publicRoutes,
    {
      element: <PrivateRoute msalInstance={instance} />,
      children: [
        ...privateRoutes,
      ]
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]

  const routes = useRoutes(routesConfig)

  return routes
}

export default Router
