/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ReactComponent as PlayIcon } from 'neptune-react-core/src/assets/images/icons/play.svg'

import { PageContent, PageTitle, PageWrapper } from 'neptune-web-core/src/components/layouts'
import { VideosResource } from 'neptune-web-core/src/api/resources'
import { useUtilityMenu } from 'neptune-web-core/src/context'
import { withInactiveUtilityMenu } from 'neptune-web-core/src/hocs'

import './index.scss'

const VideosShowPage = () => {
  const { setUtilMenuContent, setUtilMenuTitle, setUtilMenuIcon } = useUtilityMenu()
  const [video, setVideo] = useState(null)
  const { id } = useParams()

  const getVideos = async () => {
    const response = await VideosResource.getShow({
      token: process.env.REACT_APP_FILE_STORAGE_TOKEN,
      fileName: id,
    })
    setVideo(response)
  }

  useEffect(() => {
    setUtilMenuContent(null)
    setUtilMenuIcon(<PlayIcon />)
    setUtilMenuTitle('Content')
    getVideos()
  }, [])

  if (!video) return null

  return (
    <PageWrapper name="VideosShowPage">
      <PageTitle>Content</PageTitle>
      <PageContent>
        <video src={video.file_location} controls style={{ height: '100%', width: '100%' }} />
      </PageContent>
    </PageWrapper>
  )
}

export default withInactiveUtilityMenu(VideosShowPage)
